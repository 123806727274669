/* eslint-disable jsx-a11y/media-has-caption */

import "./styles.scss"

import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"

const Content = () => {
  const [isVideoActive, setIsVideoActive] = useState(false)
  const videoRef = useRef(null)

  useEffect(() => {
    if (isVideoActive && videoRef.current) {
      videoRef.current.play()
    }
  }, [isVideoActive])

  return (
    <section className="christmas-content">
      <div className="container-fluid">
        <motion.div {...fadeAnimation}>
          <ul className="christmas-content__breadcrumb">
            <li>
              <Link to="/">Strona główna</Link>
            </li>
            <li>
              <span>Wesołych Świąt</span>
            </li>
          </ul>
          <h1>Wesołych Świąt!</h1>
        </motion.div>
        <motion.div className="christmas-content__top" {...fadeAnimation}>
          <h2>Szanowni Państwo,</h2>
          <p>
            Z okazji zbliżających się Świąt Bożego Narodzenia życzymy Państwu
            dużo radości, ciepła i niepowtarzalnej, rodzinnej atmosfery.
            Dziękując za okazane nam zaufanie, pragniemy przekazać Państwu
            drobny upominek:
          </p>
        </motion.div>
        <motion.div className="christmas-content__video" {...fadeAnimation}>
          <div className="christmas-content__video-box">
            <AnimatePresence mode="wait">
              {isVideoActive ? (
                <motion.video
                  key="video"
                  controls
                  ref={videoRef}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: "tween", duration: 0.3 }}
                >
                  <source
                    src={
                      require("assets/videos/christmas-video-2023.mp4").default
                    }
                    type="video/mp4"
                  />
                </motion.video>
              ) : (
                <motion.div
                  key="placeholder"
                  className="christmas-content__video-wrapper"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: "tween", duration: 0.3 }}
                >
                  <button
                    className="christmas-content__video-button"
                    type="button"
                    onClick={() => setIsVideoActive(true)}
                  >
                    Play video
                    <img
                      src={
                        require("assets/images/christmas/christmas-video-icon.svg")
                          .default
                      }
                      alt=""
                    />
                  </button>
                  <div
                    className="christmas-content__video-placeholder"
                    style={{
                      backgroundImage: `url(${
                        require("assets/images/christmas/christmas-video-placeholder.jpg")
                          .default
                      })`,
                    }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <motion.div
              className="christmas-content__video-present"
              initial={{
                scale: 0,
              }}
              whileInView={{
                scale: 1,
              }}
              viewport={{
                once: true,
                margin: "-60px",
              }}
              transition={{
                type: "spring",
                stiffness: 160,
                damping: 6,
              }}
            />
          </div>
        </motion.div>
        <motion.div className="christmas-content__center" {...fadeAnimation}>
          <p>
            Wspólnie z duetem Paprocki Brzozowski, przygotowaliśmy dla Państwa
            świąteczny upominek -
          </p>
          <h4>
            specjalny RABAT w wysokości 15%
            <br />
            do atelier Paprocki Brzozowski.
          </h4>
        </motion.div>
        <motion.div className="christmas-content__code" {...fadeAnimation}>
          <p>Kod rabatowy:</p>
          <div className="christmas-content__code-placeholder">TRUST</div>
          <div className="christmas-content__code-link">
            <a
              href="https://paprockibrzozowski.com/atelier/"
              target="_blank"
              rel="noreferrer"
            >
              Odwiedź atelier
            </a>
          </div>
        </motion.div>
        <motion.div className="christmas-content__bottom" {...fadeAnimation}>
          <h3>
            Wesołych Świąt
            <br />i szczęśliwego Nowego Roku!
          </h3>
          <p>Zespół Trust Investment</p>
        </motion.div>
      </div>
    </section>
  )
}

const fadeAnimation = {
  initial: {
    y: 24,
    opacity: 0,
  },
  whileInView: {
    y: 0,
    opacity: 1,
  },
  viewport: {
    once: true,
    margin: "-60px",
  },
  transition: {
    type: "tween",
    duration: 0.3,
    delay: 0.15,
  },
}

export default Content
