import React from "react"

import Layout from "components/Layout"

import { Content } from "page_components/christmas"

const Christmas = ({ location }) => {
  const title = "Wesołych Świąt!"

  return (
    <Layout location={location} seo={{ title: title, noIndex: true }}>
      <Content />
    </Layout>
  )
}

export default Christmas
